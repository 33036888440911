import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import axios from "axios";
import { google_Api_Key } from "../../Helper";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const Map = ({ height, propertyListing}) => {

  const [center, setCenter] = useState(null);
  const location = useLocation()
  let zipCode = useSelector((state) => state?.persistedReducer?.zip?.zip)
  const officeZip = useSelector((state) => state?.persistedReducer?.webData?.zip)
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: google_Api_Key,
  });


  useEffect(() => {
    if (zipCode) {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${location?.pathname === '/contact-us'?officeZip:zipCode}&key=${google_Api_Key}`
        )
        .then((response) => {
          if (response.data.results.length > 0) {
            const location = response.data.results[0].geometry.location;
            setCenter({ lat: location.lat, lng: location.lng });
          } else {
            console.error("No results found for this zip code.");
          }
        })
        .catch((error) => console.error("Error in geocoding:", error));
    }
  }, [zipCode]);

  if (!isLoaded) {
    return <div>Loading map...</div>;
  }


  return (
    <>
     {location?.pathname === '/contact-us'?
    <div className="mapWrapper" style={{ height }}>
    {center ? (
     <GoogleMap
      mapContainerStyle={{ width: "100%", height: "100%" }}
     center={center}
     zoom={5}
   >
   </GoogleMap>
 ) : (
  <div>Loading map for the zip code...</div>
 )}
</div>:
     <div className="mapWrapper" style={{ height }}>
     <GoogleMap mapContainerStyle={{ width: "100%", height: "100%" }} zoom={5} center={center}>
       {propertyListing?.propertyData?.map((location, index) => {
         if (location.latitude && location.longitude) {
           return (
             <Marker
               key={index}
               position={{ lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) }}
             />)
         }
       })}
     </GoogleMap>
   </div>}
    </>
   

  );
};

export default Map;