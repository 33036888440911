import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Survey } from 'survey-react';
import 'survey-core/defaultV2.min.css';
import './surveystyle.css';

const SurveyComponent = () => {
    const [surveyJson, setSurveyJson] = useState(null);


    useEffect(() => {
        axios.get('https://sunbird-staging.aleaspeaks.com/api/v1/convert-to-surveyJs', {
            params: {
                flow_id: 113
            }
        })
            .then((res) => {
                const modifiedJson = {
                    ...res.data,
                    showQuestionNumbers: false,
                };
                console.info("JSON", modifiedJson);
                setSurveyJson(modifiedJson);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    const onComplete = (survey) => {
        // Transform survey data into the required format
        const answerData = Object.keys(survey.data).map((key) => {
            const question = survey.getQuestionByName(key);  // Get the question by name
            return {
                field_name: question.title, // Use the question title for field_name
                answer: survey.data[key],   // Use the user's answer for answer
                question_id: question?.jsonObj?.question_id,  // Assuming question_id is part of your JSON structure
            };
        });

        // Final data to send
        const payload = {
            flow_id: 113,  // Replace this with the actual flow ID if it's dynamic
            answerData: answerData
        };


        // Send the transformed data to the API
        axios.post('https://sunbird-staging.aleaspeaks.com/api/v1/session-bulk', payload)
            .then((res) => {
                console.info("Response from server:", res);
            })
            .catch((err) => {
                console.error("Error sending survey results:", err);
            });
    };

    // This function gets called after each question is rendered
    const onAfterRenderQuestion = (survey, options) => {
        console.info("ONONON", options.question);
        // Check if the current question is the one that should display an input type="time"
        if (options.question.type === "time") {
            const questionElement = options.htmlElement;
            const inputField = questionElement.querySelector('input');

            // Modify or customize the input field here, e.g., adding a placeholder or default value
            if (inputField) {
                inputField.setAttribute('placeholder', 'Select time');
                inputField.setAttribute('min', '09:00');
                inputField.setAttribute('max', '18:00');
            }
        }
    };

    return (
        <div>
            {surveyJson ? (
                <Survey
                    json={surveyJson}
                    onComplete={onComplete}
                    onAfterRenderQuestion={onAfterRenderQuestion}
                />
            ) : (
                <p>Loading survey...</p>
            )}
        </div>
    );
};

export default SurveyComponent;
