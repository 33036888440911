import React from "react";
import FeaturedSection from "../../components/features/features";
import BannerSection from "./banner";
import CTA from "../../components/cta/cta";


const Homepage = () => {

    return(
        <div className="homepageWrapper">
            <BannerSection />
            <FeaturedSection />
            <CTA />
        </div>
    );
};

export default Homepage;
