import React from "react";
import { TfiLocationPin } from "react-icons/tfi";
import { IoCallOutline } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";
import Map from "../property/map";
import ContactForm from "./form";
import { useSelector } from "react-redux";

const ContactUs = () => {
    const state = useSelector((state)=>state?.persistedReducer?.banner)
    const officeAddress = useSelector((state) => state?.persistedReducer?.webData)
    return (
        <div className="contactWrapper" >
            <div className="pageHeader" style={{
            backgroundImage: state?.contact
              ? `url(https://rainfrog-api.aleaspeaks.com/storage/${state?.contact})`
              : `url('../../assets/images/contactUs.jpg')`,
          }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="pageHeading">
                                <h3>Contact Us</h3>
                                <p>Explore our comprehensive listings of residential properties, from cozy starter homes to luxurious estates.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="innerWrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="titleSection">
                                <h5 className="topTitle">Contact Us</h5>
                                <h3 className="mainTitle">Get In Touch</h3>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="card contactDtlCard">
                                <div className="card-body">
                                    <div className="cardIcon">
                                        <TfiLocationPin />
                                    </div>
                                    <div className="cardContent">
                                        <h4 className="cardTitle">
                                            Address
                                        </h4>
                                        <p>{officeAddress?.address}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="card contactDtlCard">
                                <div className="card-body">
                                    <div className="cardIcon">
                                        <IoCallOutline />
                                    </div>
                                    <div className="cardContent">
                                        <h4 className="cardTitle">
                                            Phone
                                        </h4>
                                        <p>{officeAddress?.contact_number}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="card contactDtlCard">
                                <div className="card-body">
                                    <div className="cardIcon">
                                        <MdOutlineMail />
                                    </div>
                                    <div className="cardContent">
                                        <h4 className="cardTitle">
                                            Email
                                        </h4>
                                        <p>{officeAddress?.email}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-6">
                            <div className="formWrapper">
                                <ContactForm />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mapSection ps-5">
                                <Map height={260} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ContactUs;