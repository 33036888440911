import { createApi, } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Helpers/authHelper";


export const getApi = createApi({
    reducerPath: "getApi",
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getNearProperty: builder.query({
            query: (page) => {
                if (page)
                    return {
                        url: `get-property-near-me?page=${page}`,
                        method: "GET",
                    }
            },
            providesTags: ["nearProperty"],
        }),
        getRecentlyProperty: builder.query({
            query: () => ({
                url: "recently-viewed-properties",
                method: "GET",
            }),
            providesTags: ["RecentlyProperty"],
        }),
        favouriteProperty: builder.query({
            query: () => ({
                url: "favorite_property",
                method: "GET",
            }),
            providesTags: ["FavouriteProperty"],
        }),
        getUserDetail: builder.query({
            query: () => ({
                url: "user-detail",
                method: "GET",
            }),
            providesTags: ["UserDetail"],
        }),
        getPropertyDetails : builder.query({
            query: (id) => ({
                url:`property/${id}`,
                method: "GET",
            }),
            providesTags: ["PropertyDetails"],
        }),
        getLocation : builder.query({
            query: (nearMe) => {
                if(nearMe)
                return{
                    url:`get-ip`,
                    method: "GET",
                }
               
            },
            providesTags: ["IpAddress"],
        }),
        getRegisterUserDetails: builder.query({
            query: (token) => {
                if (token) {
                    return {
                        url: `user-info/${token}`,
                        method: "GET"
                    };
                }
               
            },
            providesTags: ["RegisterUserDetails"],
        }),
      
        getTransaction : builder.query({
            query: () => ({
                url:`transactions`,
                method: "GET",
            }),
            providesTags: ["Transaction"],
        }),
        getBlogList : builder.query({
            query: () => ({
                url:`blogs`,
                method: "GET",
            }),
            providesTags: ["BlogList"],
        }),
        getBlogDetails : builder.query({
            query: (id) => ({
                url:`blogs/${id}`,
                method: "GET",
            }),
            providesTags: ["BlogDetails"],
        }),
        getRetryPayment : builder.query({
            query: (retry) => {
                if(retry){
                    return{
                        url:`user/retry_payment`,
                        method: "GET",
                    }
                  
                }
                
            },
            providesTags: ["RetryPayment"],
        }),
         getFeaturedProperty: builder.query({
            query: () => {
                    return{
                        url:`get-featured-properties`,
                        method: "GET",
                    }                
            },
            providesTags: ["FeaturedProperty"],
        }),
     
    }),

    tagTypes: ["nearProperty", "RecentlyProperty", "FavouriteProperty", "UserDetail","PropertyDetails","IpAddress","RegisterUserDetails","Transaction","BlogList","BlogDetails","RetryPayment","FeaturedProperty"]
});


export const { useGetNearPropertyQuery, useGetRecentlyPropertyQuery, useFavouritePropertyQuery, useGetUserDetailQuery,useGetPropertyDetailsQuery,useGetLocationQuery,useGetRegisterUserDetailsQuery,useGetTransactionQuery,useGetBlogDetailsQuery,useGetBlogListQuery ,useGetRetryPaymentQuery,useGetFeaturedPropertyQuery} = getApi;
